import React, { useState } from 'react';
import './App.css';
import env from "react-dotenv";

function App() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedPhone = formatPhoneNumber(phone);

    if (!formattedPhone) {
      setMessage('Please provide 10 digit phone number.');
      return;
    }

    // Set default values for first name and last name if not provided
    const defaultFirstName = firstName || 'John';
    const defaultLastName = lastName || 'Smith';

    setMessage('Dialing...');

    const data = {
      tenant_uri: "nations",
      first_name: defaultFirstName,
      last_name: defaultLastName,
      email: "david@tenyx",
      phone: formattedPhone,
      call_now: true
    };

    try {
      const response = await fetch('https://us-east1-b-0.tenyx.io/switchboard/outbound/call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "aHZqZmd2b2V6THNhcFVXenFmRTZUc0E4VlQzanVLQGcK"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setMessage('');
      } else {
        setMessage('Call failed. Please try again.');
      }
    } catch (error) {
      setMessage('Call failed. Please try again.');
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    if (cleaned.length === 10) {
      return '+1' + cleaned;
    } else {
      return null;
    }
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setMessage('');  // Clear the message when the user starts typing a new phone number
  };

  return (
    <div className="App">
        <form onSubmit={handleSubmit}>
            <div>
            <label>
                First Name:
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </label>
            </div>
            <div>
            <label>
                Last Name:
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </label>
            </div>
            <div>
            <label>
                Phone Number:
                <input type="text" value={phone} onChange={handlePhoneChange} />
            </label>
            </div>
            <div>
            <label>
                Email Address:
                <input type="email" value="david@tenyx.com" disabled className="disabled-input" />
            </label>
            </div>
            {message && <div style={{ color: message === 'Dialing...' ? 'green' : 'red' }}>{message}</div>}
            <div className="button-container">
            <button type="submit">Submit</button>
            </div>
        </form>
    </div>
  );
}

export default App;
